.LiveStreamingmain {
    width: 100%;
    display: flex;
}

.main__left {
    /* display: flex;
    justify-content: center; */
    width: 60%;
}

video {
    height: 500px;
    width: 100%;
}

.LiveStreamingmain h1 {
    font-size: 28px;
    text-indent: 20px;
    font-family: 700;
}


/* 
audio {
    height: 300px;
    width: 400px;
} */

.details {
    display: flex;
    justify-content: space-around;
}

.messages {
    display: flex;
    flex-direction: column;
}

.messages span {
    font-weight: 700;
}

.messages .avatar {
    float: left;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    /* text-align: start; */
}

.messages div {
    margin-bottom: 10px;
}

.main__right {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 90vh;
}

.main__header {
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
}

.commentImage {
    float: left;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.main__chat_window {
    overflow-y: scroll;
    height: 70vh;
}

.main__message_container {
    width: 100%;
    /* height: 100%; */
    /* align-items: flex-end; */
    /* align-content: flex-end; */
}

.messageContainer {
    width: 100%;
    display: flex;
}

.messageContainer input {
    width: 100%;
}

.addComment {
    width: 100%;
    text-align: end;
}