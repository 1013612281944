@tailwind base;
@tailwind components;
@tailwind utilities;
.sidebar-item {
    @apply flex space-x-3 text-sidebar font-light;
}

.addcategories {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 50px;
}

.h3category {
    text-align: center;
}

.addcategories input {
    height: 40px;
}

.recordingdiv {
    text-align: end;
}