body {
    margin: 0;
    padding: 0;
}

.wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 0.6fr 3fr;
    grid-template-rows: 0.1fr 1fr;
    grid-template-areas: "nav nav" "sidebar main";
    height: 100vh;
    overflow: hidden;
}

@media only screen and (max-width: 1024px) {
    .wrapper {
        display: grid;
        grid-template-columns: 0.6fr 3fr;
        grid-template-rows: 0.1fr 1fr;
        grid-template-areas: "nav nav" "main main";
        height: 100vh;
        overflow: hidden;
    }
}

.nav {
    grid-area: nav;
}

.sidebar {
    grid-area: sidebar;
    overflow: auto;
}

.main {
    grid-area: main;
    background: #efefef;
    overflow-y: scroll;
}

.googlelogin {
    box-shadow: none !important;
    /* margin: 0 !important; */
    padding: 0 !important;
}

.input-tag {
    background: white;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
}

.input-tag input {
    border: none;
    width: 100%;
}

.input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
}

.input-tag__tags li {
    align-items: center;
    background: rgb(150, 144, 144);
    border-radius: 2px;
    color: black;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.input-tag__tags li button {
    align-items: center;
    appearance: none;
    border: none;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
}

.follow {
    margin-left: 10px;
    cursor: pointer;
}