.searchprofiles {
    margin-bottom: 20px;
}

.profiles {
    display: flex;
    flex-direction: column;
}

.profiles span {
    font-weight: 700;
}

.profiles img {
    float: left;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    /* text-align: start; */
}