.followinglist {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}


/* .followerslist {
    display: flex;
    justify-content: space-around;
} */

.viewbtn {
    padding: 10px;
    font-size: 18px;
    background-color: black;
    color: white;
}

.followinglistprofiles {
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
}

.searchprofilesfollowers img {
    float: left;
    border-radius: 50%;
}

.searchprofilesfollowers span {
    font-size: 20px;
    align-self: center;
}

.searchprofilesfollowers .centertext {
    display: flex;
    align-self: center;
    align-items: center;
}

.searchprofilesfollowers .followername {
    margin-left: 10px;
}

@media only screen and (max-width: 800px) {
    .followinglistprofiles {
        width: 100%;
        margin-top: 20px;
    }
}

.nofollower {
    font-size: 18px;
}