.addCommentdiv {
    width: 100%;
    text-align: end;
}

.addCommentbtn {
    background-color: black;
    color: white;
    padding: 10px;
}

.addCommentAvatar {
    margin-right: 10px;
}

.Audio__chat_window {
    overflow-y: scroll;
    height: 40vh;
}

.commentScreen {
    min-width: 300px;
}

.leftPartComment {
    min-width: 500px;
}

@media only screen and (max-width: 800px) {
    .leftPartComment {
        min-width: 400px;
    }
    .commentScreen {
        min-width: 400px;
    }
}

@media only screen and (max-width: 550px) {
    .leftPartComment {
        min-width: 300px;
    }
    .commentScreen {
        min-width: 300px;
    }
}